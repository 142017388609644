export default {
  title: {
    id: 'mobile_service_redirect_modal_title',
    defaultMessage: 'Redirecting to {mobileService}',
  },
  text1: {
    id: 'mobile_service_redirect_modal_text1',
    defaultMessage:
      'To get help with your mobile plan, we’re sending you to the {mobileService} website.',
  },
  text2: {
    id: 'mobile_service_redirect_modal_text2',
    defaultMessage:
      'If you choose to cancel your mobile plan, it might cancel all other bundled devices and/or services.',
  },
  cta: {
    id: 'mobile_service_redirect_modal_cta',
    defaultMessage: 'Go to {mobileService} site',
  },
}
